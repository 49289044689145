<template>
	<div>
		<Navbar />
		<Header title="Campaigns" excerpt="Manage your campaigns and create new ones here.">
			<HeaderButton title="Create" excerpt="You can create new campaigns" route="/campaigns/create" />
			<HeaderButton title="Send" excerpt="You can send new messages" route="/campaigns/compose" />
		</Header>

		<!-- CONTENT -->
		<b-container>
			<b-card no-body style="border-color: #e1e6ee; border-width: 2px;">
				<b-card-body class="p-0">
					<b-table hover :items="campaigns" :fields="fields">
						<template #cell(mask)="mask">{{ mask.value.name }}</template>
						<template #cell(status)="status">{{ status.value.charAt(0).toUpperCase() + status.value.slice(1) }}</template>
						<template #cell(schedule)="schedule">{{ new Date(schedule.value * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</template>
						<template #cell(time)="time">{{ new Date(time.value.created * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</template>
					</b-table>
				</b-card-body>
			</b-card>
			<b-pagination
				class="float-right mt-2"
				:total-rows="this.$store.state.campaigns.pages.total * this.$store.state.campaigns.pages.limit"
				:per-page="this.$store.state.campaigns.pages.limit"
				:value="this.$store.state.campaigns.pages.current"
				:hide-goto-end-buttons=true
				v-on:change="On_Paginate">
			</b-pagination>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import HeaderButton from "@/components/Header/Button";

export default {
	name: "Campaigns",
	components: { Header, Navbar, HeaderButton },

	data: () => ({
		fields: [
			{
				key: "id",
				label: "#",
				tdClass: "text-center",
				thClass: "text-center"
			},
			{
				key: "mask",
				label: "From"
			},
			{
				key: "schedule",
				label: "Scheduled On",
				tdClass: "text-center",
				thClass: "text-center"
			},
			{
				key: "status",
				label: "Status",
				sortable: true,
				tdClass: "text-center",
				thClass: "text-center"
			},
			{
				key: "time",
				label: "Created On",
				tdClass: "text-center",
				thClass: "text-center"
			}
		]
	}),

	computed: {
		campaigns() { return this.$store.state.campaigns.campaigns }
	},

	mounted() {
		this.$store.dispatch("campaigns/initialize")
	},

	methods: {

		On_Paginate: function(page) { this.$store.dispatch("history/fetch", { page: page }) }

	}
}
</script>
